import React, { useEffect, useRef } from "react";
import { Route, Routes, useLocation, useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";

import { Block, BlockHeading, BlockParagraph } from "./block-components";
import TeamSelection from "./team-selection";
import NavDots from "./nav-dots";

const API = "https://www.api.howgoodismyteam.com";

const AppContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Plot = styled.img`
  width: 100%;
  margin-bottom: 48px;
`;

const AppTitle = styled.div`
  font-size: 4em;
  font-weight: 200;
  line-height: 1.3;
  color: white;
  opacity: 0.9;
  margin-bottom: 48px;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 6em;
    margin-bottom: 96px;
  }
  @media (min-width: 992px) {
    font-size: 8em;
    margin-bottom: 96px;
  }
`;

const Highlight = styled.span`
  background-color: white;
  color: #28282D;
  padding-left: 0.2em;
  padding-right: 0.2em;
  // &:before {
  //   content: "good";
  //   animation: topToBottom 4s infinite 0s;
  // }
  // @keyframes topToBottom {
  //   0% {
  //     content: "good";
  //   }
  //   50% {
  //     content: "bad";
  //   }
  // }
`;

const AppDescription = styled.div`
  font-size: 1em;
  font-weight: 300;
  line-height: 1.7;
  max-width: 100%;
  color: white;
  opacity: 0.7;
  @media (min-width: 768px) {
    max-width: 75%;
    font-size: 1.5em;
  }
  @media (min-width: 992px) {
    max-width: 50%;
    font-size: 1.5em;
  }
`;

const Copyright = styled.div`
  font-size: 0.9em;
  font-weight: 300;
  opacity: 0.3;
  margin-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  position: relative;
  font-size: 1em;
  font-weight: 300;
  color: white;
  padding-left: 4px;
  padding-right: 4px;
  &::before {
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 11px;
    z-index: -1;
    transition: all .3s ease-in-out;
  }
  &:hover::before {
    bottom: -1px;
    height: 110%;
  }
`;

export default function App() {

  const [selectedTeam, setSelectedTeam] = React.useState({
    label: "Liverpool",
    type: "Premier League",
    file: "Liverpool"
  });

  const [darkMode, setDarkMode] = React.useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const scrollRef = useRef(null);
  // document.getElementById("block-3")

  const navData = [
    { id: "block-1", label: "Select a team" },
    { id: "block-2", label: "Select a season" },
    { id: "block-3", label: "History" },
    { id: "block-4", label: "Goals" },
  ];

  const scrollToPlot = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  const app =
    <AppContainer>
      <Block id="block-1" theme="dark">
        <AppTitle>
          How <Highlight>good</Highlight> is your team?
        </AppTitle>
        <AppDescription>
          Do you support an English football team? See how your team has faired from season to season across the top four tiers of the English football pyramid from the first ever professional football match in the 1890-91 season through the end of the 2023-24 season.
        </AppDescription>
      </Block>
      <TeamSelection id="block-2" selected={selectedTeam.label} onClick={(team) => {
        setSelectedTeam(team);
        scrollToPlot();
      }} />
      <div ref={scrollRef} />
      <Block id="block-3" theme="dark">
        <BlockHeading>
          {selectedTeam.label}'s top-tier history.
        </BlockHeading>
        <BlockParagraph>
          This visualization shows {selectedTeam.label}'s position within the top four tiers of the English football pyramid for each season, from the 1890-91 season through 2023-24 season. The bold white line indicates the team's position in the standings at the end of each season. Gaps in this line indicate periods of time that the team wasn't in the top four tiers.
        </BlockParagraph>
        <Plot src={"plots/history-" + selectedTeam.file + ".png"} />
        <BlockParagraph>
          The horizontal white lines indicate the divisions between the leagues. These have changed over time as new leagues were created and as the number of teams in each league has changed. The bold white line crossing one of these horizontal white lines indicates a promotion or relegation.
        </BlockParagraph>
      </Block>
      <Block id="block-4" theme="dark">
        <BlockHeading>
          About.
        </BlockHeading>
        <BlockParagraph>
          This data visualization project was created by Josh Fjelstul, Ph.D. Josh is a political economist, data scientist, and machine learning engineer. This project uses data from the Fjelstul English Football Database, which includes the results of every professional match played in the Premier League and the English Football League from the inaugural season of the Football League in 1888-89 through the 2023-24 season. You can download the data from <ExternalLink href="https://github.com/jfjelstul/englishfootball">GitHub</ExternalLink>. You can also access the data in R via the <i>englishfootball</i> package. You can install the package from <ExternalLink href="https://github.com/jfjelstul/englishfootball">GitHub</ExternalLink>.
        </BlockParagraph>
        <Copyright>
          © 2024 Josh Fjelstul, Ph.D. 
        </Copyright>
      </Block>
      {/* <NavDots data={navData} /> */}
    </AppContainer>

  return (
    app
  );
};
