import styled from "styled-components";

const BlockWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 16px;
  padding-right: 16px;
  background: ${props => props.background};
  color: ${props => props.color};
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  @media (min-width: 768px) {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (min-width: 992px) {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 10%;
    padding-right: 10%;
  }
`;

const BlockTitle = styled.div`
  font-size: 2em;
  font-weight: 200;
  line-height: 1.3;
  margin-bottom: 48px;
  opacity: 0.9;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 3.5em;
  }
  @media (min-width: 992px) {
    font-size: 5em;
  }
`;

const BlockHeading = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 32px;
  line-height: 1.3;
  opacity: 0.9;
  @media (min-width: 768px) {
    font-size: 2em;
  }
  @media (min-width: 992px) {
    font-size: 2.5em;
  }
`;

const BlockParagraph = styled.div`
  font-size: 1em;
  font-weight: 300;
  margin-bottom: 32px;
  line-height: 1.7;
  color: white;
  opacity: 0.7;
`;

const Block = (props) => {

  var { theme } = props;
  var color;
  var background;

  if (theme === "white") {
    background = "white";
    color = "#28282D";
  }

  if (theme === "light") {
    background = "rgba(0, 0, 0, 0.02)";
    color = "#28282D";
  }

  if (theme === "dark") {
    background = "#28282D";
    color = "white";
  }

  if (theme === "primary") {
    background = "#28282D";
    color = "white";
  }

  return (
    <BlockWrapper background={background} color={color}>
      {props.children}
    </BlockWrapper>
  )
}

// const BlockSeparator = (props) => {

//   var { shape, top, bottom, flip } = props;

//   if (flip === undefined) {
//     flip = false;
//   }

//   var scale = "scale(1, 1)";

//   if (flip) {
//     scale = "scale(-1, 1)";
//   }

//   if (top === "light") {
//     top = "#F7F7F7";
//   }

//   if (top === "dark") {
//     top = "#454C55";
//   }

//   if (top === "primary") {
//     top = "#57C1F6";
//   }

//   if (bottom === "light") {
//     bottom = "#F7F7F7";
//   }

//   if (bottom === "dark") {
//     bottom = "#454C55";
//   }

//   if (bottom === "primary") {
//     bottom = "#57C1F6";
//   }

//   var d;

//   if (shape === 1) {
//     d = "M 0 64 C 0 64 256 0 512 32 C 768 64 1024 0 1024 0 L 0 0 L 0 64 Z";
//   }

//   if (shape === 2) {
//     d = "M 0 0 L 0 32 C 0 32 256 0 512 32 C 768 64 1024 64 1024 64 L 1024 0 L 0 0 Z";
//   }

//   if (shape === 3) {
//     d = "M 0 0 C 0 0 128 32 512 32 C 896 32 1024 64 1024 64 L 1024 0 L 0 0 Z";

//   }

//   if (shape === 4) {
//     d = "M 0 0 L 0 32 C 0 32 256 64 512 64 C 768 64 1024 32 1024 32 L 1024 0 L 0 0 Z";
//   }

//   if (shape === 5) {
//     d = "M 0 0 L 0 32 C 0 32 256 0 512 0 C 768 0 1024 32 1024 32 L 1024 0 L 0 0 Z";
//   }

//   const Wrapper = styled.div`
//       width: 100%;
//       background-color: ${bottom};
//       transform: ${scale};
//     `;

//   return (
//     <Wrapper>
//       <svg width="100%" height="100%" viewBox="0 0 1024 64">
//         <path d={d} style={{ "fill": top }} />
//       </svg>
//     </Wrapper>
//   );
// };

export { Block, BlockTitle, BlockHeading, BlockParagraph };
