import React from "react";
import styled from "styled-components";
import { Block, BlockTitle } from "./block-components";

import data from "./team-data.json";

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: calc(48px - 16px);
`;

const Tab = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: -4px;
  cursor: pointer;
  transition: 0.3s;
  opacity: ${props => props.selected ? "0.9" : "0.3"};
  border-bottom: ${props => props.selected ? "1px solid #ffffff" : "1px solid #ffffff00"};
  &:hover {
    opacity: 1;
  }
  @media (min-width: 768px) {
    font-size: 2em;
  }
  @media (min-width: 992px) {
    font-size: 2em;
  }
`;

const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: scroll;

  width: calc(100vw - 24px);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  margin-left: -16px;
  padding-left: 8px;
  margin-bottom: -16px;

  @media (min-width: 768px) {
    width: 100%;
    border-right: unset;
    margin-left: -16px;
  }
`;

const SkillWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 300%;
  overflow-x: scroll;
  @media (min-width: 768px) {
    width: 100%;
  }
`;

const Skill = styled.div`
  font-weight: 300;
  color: ${props => props.selected ? "#28282D" : "#ffffff"};
  background-color: ${props => props.selected ? "#ffffff" : "#28282D"};
  opacity: ${props => props.highlighted ? "0.9" : "0.3"};
  font-size: 1em;
  margin-right: 12px;
  margin-bottom: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid #ffffff00;
  transition: 0.3s;
  cursor: pointer;
  line-height: 1;
  &:hover {
    opacity: 0.9;
    color: #28282D;
    background-color: #ffffff;
  }
  @media (min-width: 768px) {
    font-size: 1.1em;
    margin-right: 20px;
    margin-bottom: 12px;
  }

  @media (min-width: 992px) {
    font-size: 1.2em;
    margin-right: 18px;
    margin-bottom: 12px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  @media (min-width: 768px) {
    display: flex;
  }
`;

const SkillsSection = (props) => {

  const { selected, onClick } = props;

  const [highlighted, setHighlighted] = React.useState("Premier League");
  const [hovered, setHovered] = React.useState("Premier League");

  return (
    <Wrapper>
      <Block theme="dark">
        <BlockTitle>
          Choose a team.
        </BlockTitle>
        <TabWrapper>
          <Tab selected={highlighted === "Premier League"} onMouseOver={() => setHighlighted("Premier League")}>
            The Premier League
          </Tab>
          <Tab selected={highlighted === "Championship"} onMouseOver={() => setHighlighted("Championship")}>
            The Championship
          </Tab>
          <Tab selected={highlighted === "League One"} onMouseOver={() => setHighlighted("League One")}>
            League One
          </Tab>
          <Tab selected={highlighted === "League Two"} onMouseOver={() => setHighlighted("League Two")}>
            League Two
          </Tab>
          {/* <Tab selected={type === "Former EFL Members"} onMouseOver={() => setType("Former EFL Members")}>
            Former EFL Members
          </Tab> */}
        </TabWrapper>
        <SkillContainer>
          <SkillWrapper>
            {data.map((item, i) => {
              return (
                <Skill key={i} highlighted={item.type === highlighted} selected={item.label === selected} onMouseOver={() => setHovered(item.label)} onClick={() => {
                  onClick(item);
                  setHighlighted(item.type);
                }}>
                  {item.label}
                </Skill>
              )
            })}
          </SkillWrapper>
        </SkillContainer>
      </Block>
    </Wrapper>
  )
}

export default SkillsSection;
